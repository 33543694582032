import { ButtonCc2r } from '../../components/Button'
import { S_Div, S_Title } from './style'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle'
import KeyIcon from '@mui/icons-material/Key'
import Diversity2Icon from '@mui/icons-material/Diversity2'
import { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import BadgeIcon from '@mui/icons-material/Badge'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import { MuiTelInput } from 'mui-tel-input'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { useNavigate } from 'react-router-dom'
import { forwardRef } from 'react'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SignUp = () => {
  let navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [confPassword, setConfPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [username, setUsername] = useState('')
  const [userNameFailed, setUsernameFailed] = useState(false)

  const handleUsername = (newUsername) => {
    if (newUsername.length >= 6) setUsernameFailed(false)
    setUsername(newUsername.replace(/\s/g, ''))
    setUsernameFailed(false)
  }

  const changePass = (value) => {
    setPassword(value)
    setIsFailed(false)
  }
  const changeConfPass = (value) => {
    setConfPassword(value)
    setIsFailed(false)
  }

  const handleClose = () => {
    setOpen(false)
    navigate('/')
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    if (password === confPassword) {
      let formData = new FormData(e.target)
      let checkForm = new FormData()

      checkForm.set('username', username)

      const checkUsername = await fetch(
        'https://agenda-ac2r.cc-deuxrives.fr/users/check_username',
        {
          method: 'POST',
          body: checkForm,
          credentials: 'include',
        }
      )

      let repo = await checkUsername.json()

      if (+repo.loginidentique === 0) {
        fetch('https://agenda-ac2r.cc-deuxrives.fr/users/create', {
          method: 'POST',
          body: formData,
          credentials: 'include',
        })
          .then(function (response) {
            return response.json()
          })
          .then(function (json) {
            setIsLoading(false)
            if (json.etat === 'En attente de validation') {
              setOpen(true)
            } else {
              setIsFailed(true)
            }
          })
      } else {
        setIsLoading(false)
        setUsernameFailed(true)
      }
    } else {
      setIsLoading(false)
      setIsFailed(true)
    }
  }

  return (
    <>
      <S_Div onSubmit={handleSubmit}>
        <Oval
          wrapperClass="spinner"
          height={80}
          width={80}
          color="#e66c86"
          visible={isLoading}
          ariaLabel="oval-loading"
          secondaryColor="#29a78c"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        <S_Title>inscription</S_Title>
        <div
          className="login-wrapper"
          style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
        >
          <TextField
            name="username"
            error={userNameFailed}
            helperText={
              userNameFailed ? "Ce nom d' utilisateur existe déjà" : null
            }
            onChange={(e) => handleUsername(e.target.value)}
            value={username}
            inputProps={{ minLength: 6 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Nom d'utilisateur"
            required
          ></TextField>
          <TextField
            name="name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BadgeIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Nom"
            // helperText="Mot de passe erroné"
            required
          ></TextField>
          <TextField
            name="surname"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BadgeIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Prénom"
            // helperText="Mot de passe erroné"
            required
          ></TextField>
          <TextField
            name="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="email"
            label="Email"
            // helperText="Mot de passe erroné"
            required
          ></TextField>
          <TextField
            name="password"
            error={isFailed}
            value={password}
            onChange={(e) => changePass(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="password"
            label="Mot de passe"
            // helperText="Mot de passe erroné"
            required
          ></TextField>
          <TextField
            value={confPassword}
            onChange={(e) => changeConfPass(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            error={isFailed}
            type="password"
            label="Confirmer Le Mot de passe"
            helperText={
              isFailed
                ? 'Les mots de passe saisis ne sont pas identiques.'
                : null
            }
            // helperText="Mot de passe erroné"
            required
          ></TextField>
        </div>
        <div
          className="validation-btn"
          style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
        >
          <ButtonCc2r style={{ height: '48px' }} type="submit" variant="green">
            Valider
          </ButtonCc2r>
        </div>
      </S_Div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Votre compte a été créé avec succès !'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Il est maintenant en attente de validation par un administrateur.
            D'autres instructions ont été envoyées à votre adresse e-mail.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonCc2r onClick={handleClose}>Fermer</ButtonCc2r>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SignUp
