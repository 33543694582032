import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../utils/context'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { S_Div, S_Container } from './style'
import { Alert, AlertTitle, Switch } from '@mui/material'
import { Oval } from 'react-loader-spinner'
import { ButtonCc2r } from '../../components/Button'

const Admin = () => {
  const [users, setUsers] = useState([])
  const [newUsers, setNewUsers] = useState([])

  const { user, changeUserState } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    loadUsers()
  }, [user])

  useEffect(() => {}, [users])

  const handleChangeValidated = async (e, username = '', action = '') => {
    setIsLoading(true)

    const formData = new FormData()

    if (!username) {
      formData.append('username', e.target.value)
      formData.append('validated', e.target.checked ? 1 : 0)
    } else {
      formData.append('username', username)
      formData.append('validated', action)
    }

    return fetch('https://agenda-ac2r.cc-deuxrives.fr/users/validate', {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then(function (response) {
        return response
      })
      .then(function (data) {
        if (data.status === 200) {
          loadUsers()
        }
      })
  }

  const loadUsers = async () => {
    setIsLoading(true)
    return fetch('https://agenda-ac2r.cc-deuxrives.fr/users/list', {
      credentials: 'include',
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        if (data.length) {
          setUsers(data)
          setNewUsers(data.filter((user) => user.newuser == 1))
          setIsLoading(false)
        }
      })
  }

  return (
    <S_Container>
      <div className="alert-container">
        {newUsers &&
          newUsers.map((user, index) => (
            <Alert
              severity="info"
              key={index}
              className="notifications"
              style={{ width: 'fit-content' }}
            >
              <AlertTitle>Nouvelle demande de validation de compte</AlertTitle>
              De{' '}
              <strong>
                {user.name} {user.surname}
              </strong>{' '}
              pour <strong>{user.entite}</strong>
              <div className="button-container">
                <ButtonCc2r
                  variant="green"
                  onClick={(e) => handleChangeValidated(e, user.username, 1)}
                >
                  Valider
                </ButtonCc2r>
                <ButtonCc2r
                  onClick={(e) => handleChangeValidated(e, user.username, 0)}
                >
                  Refuser
                </ButtonCc2r>
              </div>
            </Alert>
          ))}
      </div>

      <S_Div>
        <TableContainer component={Paper} className="tableS">
          <Oval
            wrapperClass="spinner"
            height={80}
            width={80}
            color="#e66c86"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#29a78c"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
          <Table
            style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Actif</TableCell>
                <TableCell>Nom d'utilisateur</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Nom</TableCell>
                <TableCell align="right">Prénom</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((row) => (
                <TableRow
                  key={row.username}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Switch
                      checked={+row.validated ? true : false}
                      onChange={handleChangeValidated}
                      color="success"
                      value={row.username}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.username}
                  </TableCell>
                  <TableCell align="right">{row.entite}</TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="right">{row.surname}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </S_Div>
    </S_Container>
  )
}

export default Admin
