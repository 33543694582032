import { Scheduler } from '@aldabil/react-scheduler'
import { fr } from 'date-fns/locale'
import { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../utils/context'
import { Oval } from 'react-loader-spinner'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import Checkbox from '@mui/material/Checkbox'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import {
  TextField,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ButtonCc2r, InputCc2r } from '../../components/Button'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { S_Div, S_Title, S_Scheduler, S_List } from './style'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle'
import FestivalIcon from '@mui/icons-material/Festival'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import EuroIcon from '@mui/icons-material/Euro'
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  parse,
} from 'date-fns'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial',
  },
})

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000)
  return this
}

const CustomEditor = ({ scheduler }) => {
  const event = scheduler.edited
  const { user } = useContext(UserContext)
  const userRef = useRef(user)
  const [open, setOpen] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [state, setState] = useState({
    title: event?.title || '',
    descriptif: event?.descriptif || '',
    date: event?.start || scheduler.state.start.value,
    heure: event?.heure_event || scheduler.state.start.value,
    end: event?.end || scheduler.state.end.value,
    lieu: event?.lieu || '',
    image: event?.visuel || '',
    orga: event?.orga || user.entite,
    entrance: event?.entrance || 0,
    reason: event?.reason || '',
    deleted: +event?.deleted === 1,
    type: +event?.type != null,
  })

  const [error, setError] = useState(null)

  const handleChange = (value, name) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    // Your own validation
    if (state.title.length < 3) {
      return setError({ ...error, title: 'Min 3 letters' })
    }
    try {
      scheduler.loading(true)

      const added_updated_event = await new Promise((res) => {
        let formData = new FormData(e.target)

        let date = formData.get('date_event')
        let remove = formData.get('removed')
        let type1 = formData.get('type')

        let formatDate = date.split('/')

        let formatedDate = `${formatDate[2]}-${formatDate[1]}-${formatDate[0]}`
        formData.set('type', type1 == 'on' ? 1 : null)
        formData.set('removed', remove ? 1 : 0)
        formData.set('date_event', formatedDate)

        fetch(
          `https://agenda-ac2r.cc-deuxrives.fr/events/${
            event ? 'update' : 'create'
          }`,
          {
            method: 'POST',
            body: formData,
            credentials: 'include',
          }
        )
          .then(function (response) {
            return response.json()
          })
          .then(function (data) {
            if (data.etat === 'Event OK') {
              let hourFormat = state.heure
                .toLocaleTimeString('fr-FR')
                .split(':')
              const end = new Date()
              const start = new Date()

              console.log(formatDate)
              start.setUTCFullYear(+formatDate[2])
              start.setMonth(+formatDate[1] - 1)
              start.setDate(+formatDate[0])
              start.setHours(+hourFormat[0])
              start.setMinutes(+hourFormat[1])
              start.setSeconds(0)

              end.setUTCFullYear(+formatDate[2])
              end.setMonth(+formatDate[1] - 1)
              end.setDate(+formatDate[0])
              end.setHours(+hourFormat[0])
              end.setMinutes(+hourFormat[1])
              end.setSeconds(0)
              end.addHours(1)

              console.log(+state.type)

              setOpen(true)
              res({
                event_id: data.id_event,
                title: state.title,
                start: start,
                end: end,
                orga: state.orga,
                entrance: state.entrance,
                deleted: state.deleted,
                reason: state.deleted ? state.reason : '',
                heure: state.heure.toLocaleTimeString('fr-FR'),
                descriptif: state.descriptif,
                lieu: state?.lieu,
                type: +state?.type == null || 'null' ? false : true,
                user_id_event: data.user_id_event,
                image: data?.image,
                color: state.deleted
                  ? '#58576b'
                  : +state?.type
                  ? 'linear-gradient(90deg,#6cd8e6 0%,#202ab9 51%,#dc6ce6 100%)'
                  : 'linear-gradient(90deg,#e66c86 0%,#f19a5d 51%,#e66c86 100%)',
                editable:
                  user.role === 'administrateur' ||
                  +user.user_id === +data.user_id_event,
                deletable: false,
              })
            } else {
              setIsFailed(true)
            }
          })
      })
      scheduler.onConfirm(added_updated_event, event ? 'edit' : 'create')
      scheduler.close()
    } finally {
      setIsLoading(false)
      scheduler.loading(false)
      CustomScheduler.loadEvents()
    }
  }
  return (
    <>
      <S_Div onSubmit={handleSubmit}>
        <Oval
          wrapperClass="spinner"
          height={80}
          width={80}
          color="#e66c86"
          visible={isLoading}
          ariaLabel="oval-loading"
          secondaryColor="#29a78c"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        <S_Title>
          {event ? 'Editer un événement' : 'Ajouter un événement'}{' '}
        </S_Title>
        <div
          className="login-wrapper"
          style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
        >
          {event && (
            <div className="remove-container">
              <FormGroup>
                <FormControlLabel
                  control={
                    <>
                      {!state.deleted && (
                        <CheckCircleOutlineRoundedIcon color="success" />
                      )}
                      <Switch
                        name="removed"
                        color="error"
                        checked={state.deleted}
                        onChange={(e) =>
                          handleChange(!state.deleted, 'deleted')
                        }
                      />
                    </>
                  }
                  label={state.deleted ? 'Désactivé' : 'Publié'}
                />
              </FormGroup>
              {state.deleted && (
                <TextField
                  error={isFailed}
                  name="remove_reason"
                  value={state.reason}
                  onChange={(e) => handleChange(e.target.value, 'reason')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DeleteSweepIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  label="Motif du retrait de l'événement"
                  required
                ></TextField>
              )}
            </div>
          )}

          <TextField
            error={isFailed}
            name="name_event"
            value={state.title}
            onChange={(e) => handleChange(e.target.value, 'title')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Nom de l'événement"
            required
          ></TextField>
          <TextField
            error={isFailed}
            value={state.lieu}
            onChange={(e) => handleChange(e.target.value, 'lieu')}
            name="lieu_event"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FestivalIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Lieu"
          ></TextField>
          <TextField
            error={isFailed}
            name="descriptif"
            onChange={(e) => handleChange(e.target.value, 'descriptif')}
            value={state.descriptif}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EventNoteIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Descriptif"
            // helperText="Mot de passe erroné"
            required
          ></TextField>
          <TextField
            error={isFailed}
            value={state.orga}
            onChange={(e) => handleChange(e.target.value, 'orga')}
            name="organisateur"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CorporateFareIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            label="Organisateur"
            required
          ></TextField>
          <InputCc2r
            type="file"
            name="visuel"
            accept="image/*"
            value=""
            style={{ display: 'none' }}
          />
          <FormControl variant="standard" style={{ display: 'none' }}>
            <InputLabel id="demo-simple-select-standard-label">
              Payant
            </InputLabel>
            <Select
              name="payant"
              value={state.entrance}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => handleChange(e.target.value, 'entrance')}
              label="Payant"
            >
              <MenuItem value={1}>Oui</MenuItem>
              <MenuItem value={0}>Non</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox inputProps={{ name: 'type' }} />}
              label="Tâches"
            />
          </FormGroup>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <DatePicker
              onChange={(value) => handleChange(value, 'date')}
              variant="standard"
              value={state.date}
              label="Choisir la date"
              renderInput={(params) => (
                <TextField name="date_event" required {...params} />
              )}
            />
            <TimePicker
              ampm={false}
              onChange={(value) => handleChange(value, 'heure')}
              value={state.heure}
              label="Choisir l'heure"
              variant="standard"
              renderInput={(params) => (
                <TextField name="heure_event" required {...params} />
              )}
            />
          </LocalizationProvider>
          <input
            readOnly
            type="text"
            name="id_event"
            value={event?.event_id}
            hidden
          />
          <input
            readOnly
            type="text"
            name="user_id_event"
            value={event?.user_id_event}
            hidden
          />
        </div>
        <div
          className="validation-btn"
          style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
        >
          <DialogActions>
            <ButtonCc2r style={{ height: '48px' }} onClick={scheduler.close}>
              Annuler
            </ButtonCc2r>
            <ButtonCc2r
              style={{ height: '48px' }}
              type="submit"
              variant="green"
            >
              Valider
            </ButtonCc2r>
          </DialogActions>
        </div>
      </S_Div>
    </>
  )
}

const CustomScheduler = ({ switchList }) => {
  const [events, setEvents] = useState([])
  const { user, changeUserState } = useContext(UserContext)
  const userRef = useRef(user)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    loadEvents()
  }, [user])

  useEffect(() => {
    if (!switchList) {
      userRef.current = user
      const calendar = document.getElementsByClassName('rs__outer_table')[0]

      let foo = (e, ref) => {
        if (ref.access !== 'granted') {
          if (
            e.target.tagName !== 'H6' &&
            e.target.tagName !== 'P' &&
            e.target.tagName !== 'DIV'
          ) {
            e.preventDefault()
            e.stopPropagation()
          }
        }
      }

      if (user.access !== 'granted') {
        calendar.addEventListener('click', (e) => foo(e, userRef.current))
      } else {
        calendar.removeEventListener('click', foo)
      }
    } else {
    }
  }, [user, events, switchList])

  const loadEvents = async () => {
    setIsLoading(true)
    return fetch('https://agenda-ac2r.cc-deuxrives.fr/events/list', {
      credentials: 'include',
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        if (data.length) {
          const upEvents = []

          data?.map((data) => {
            let dateToAdapt = data.date_event.split('-')
            let hourToAdapt = data.heure_event.split(':')

            const start = new Date()
            const end = new Date()

            start.setUTCFullYear(+dateToAdapt[0])
            start.setMonth(+dateToAdapt[1] - 1)
            start.setDate(+dateToAdapt[2])
            start.setHours(+hourToAdapt[0])
            start.setMinutes(+hourToAdapt[1])
            start.setSeconds(0)

            end.setUTCFullYear(+dateToAdapt[0])
            end.setMonth(+dateToAdapt[1] - 1)
            end.setDate(+dateToAdapt[2])
            end.setHours(+hourToAdapt[0])
            end.setMinutes(+hourToAdapt[1])
            end.setSeconds(0)
            end.addHours(1)
            if (
              +data?.removed == 1 &&
              user?.role != 'administrateur' &&
              user?.user_id != data.user_id_event
            ) {
            } else {
              upEvents.push({
                event_id: data.id_event,
                title: data.name_event,
                descriptif: data.descriptif,
                lieu: data.lieu_event,
                user_id_event: data.user_id_event,
                deleted: data?.removed,
                reason: data?.removed_reason,
                image: data.visuel,
                heure: data.heure_event,
                orga: data.organisteur,
                entrance: data.payant,
                color: +data.removed
                  ? '#58576b'
                  : +data?.type
                  ? 'linear-gradient(90deg,#6cd8e6 0%,#202ab9 51%,#dc6ce6 100%)'
                  : 'linear-gradient(90deg,#e66c86 0%,#f19a5d 51%,#e66c86 100%)',
                start: start,
                end: end,
                editable:
                  user.role === 'administrateur' ||
                  +user.user_id === +data.user_id_event,
                deletable: false,
              })
            }
          })

          upEvents.sort(function (a, b) {
            return new Date(a.start) - new Date(b.start)
          })

          setEvents(upEvents)
          setIsLoading(false)
        }
        setIsLoading(false)
      })
  }
  console.log(switchList)

  const classes = useStyles()

  return (
    <>
      {!switchList && (
        <S_Scheduler loading={isLoading ? 'true' : 'false'}>
          <Oval
            wrapperClass="spinner"
            color="#e66c86"
            visible={isLoading}
            ariaLabel="oval-loading"
            secondaryColor="#29a78c"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
          <Scheduler
            customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
            viewerExtraComponent={(fields, event) => {
              return (
                <div className="viewer-extra">
                  <h3>{event.title}</h3>
                  <p>Lieu: {event.lieu} </p>
                  <p>Organisateur: {event.orga} </p>
                  <p>
                    Date:{' '}
                    {event.start.toLocaleDateString('fr-FR', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>
                  <p>Heure: {event.heure}</p>
                  <p>Descriptif: {event.descriptif}</p>
                  <img
                    src={`https://agenda-ac2r.cc-deuxrives.fr${
                      event?.image ? event?.image : ''
                    }`}
                    alt=""
                  />
                </div>
              )
            }}
            hourFormat="24"
            translations={{
              navigation: {
                month: 'Mois',
                week: 'Semaine',
                day: 'Jour',
                today: "Aujourd'hui",
              },
              form: {
                addTitle: "Ajout d'événement ",
                editTitle: "Suppression d'événement ",
                confirm: 'Confirmer',
                delete: 'Supprimer',
                cancel: 'Annuler',
              },
              event: {
                title: 'Titre',
                start: 'Début',
                end: 'Fin',
              },
              moreEvents: 'Voir plus...',
            }}
            locale={fr}
            view="month"
            week={null}
            dialogMaxWidth="lg"
            style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
            events={events}
            month={{
              weekDays: [0, 1, 2, 3, 4, 5, 6],
              weekStartOn: 1,
              startHour: 8,
              endHour: 22,
            }}
            day={{
              startHour: 6,
              endHour: 22,
              step: 60,
            }}
          />
        </S_Scheduler>
      )}
      {switchList && (
        <S_List>
          <TableContainer
            component={Paper}
            classes={{ root: classes.customTableContainer }}
            sx={{
              maxHeight: {
                sm: 'calc(100vh - 160px)',
                md: 'calc(100vh - 246px)',
              },
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead className="table-head">
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Evenement</TableCell>
                  <TableCell align="right">Organisateur</TableCell>
                  <TableCell align="right">Lieu</TableCell>
                  <TableCell align="right">Heure</TableCell>
                  <TableCell align="right">Descriptif</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((row) => {
                  let today = new Date()
                  today.setHours(0, 0, 0, 0)
                  if (row.start >= today) {
                    return (
                      <TableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.start.toLocaleDateString('fr-FR', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          })}
                        </TableCell>
                        <TableCell align="right">{row.title}</TableCell>
                        <TableCell align="right">{row.orga}</TableCell>
                        <TableCell align="right">{row.lieu}</TableCell>
                        <TableCell align="right">{row.heure}</TableCell>
                        <TableCell align="right">{row.descriptif}</TableCell>
                      </TableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </S_List>
      )}
    </>
  )
}

export default CustomScheduler
